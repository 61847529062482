<template>
  <div class='container'>
    <div class="head-bg"></div>
    <div class="steps">
      <div :class="['step']">
        <div class="circle">
          1
        </div>
        <p>选择量表</p>
      </div>
      <div :class="['step']">
        <div class="circle">
          2
        </div>
        <p>填写信息</p>
      </div>
      <div :class="['step','achieve']">
        <img src="@/assets/project/active.png" alt="">
        <p>发布成功</p>
      </div>
    </div>
    <div class="content">
      <div style="text-align: center;margin: 0 0 8vw 0  ;">
        <img src="@/assets/project/success.png" alt="" style="width: 17.3333vw;height: 17.3333vw;">
        <p style="mask-type: 1.6vw;font-size: 5.3333vw;color: #5EDBC9;">发布成功</p>
      </div>
      <div class="box">
        <img class="title-img" src="@/assets/project/success1.png" alt="">
        <p class="title">测评标题</p>
        <p>{{exam.title}}</p>
      </div>
      <div class="box">
        <img class="title-img" src="@/assets/project/success2.png" alt="">
        <p class="title">起止时间</p>
        <div>
          <p>{{exam.startTime}} 至 </p>
          <p>{{exam.endTime}}</p>
        </div>
      </div>
      <div class="box">
        <img class="title-img" src="@/assets/project/success3.png" alt="">
        <p class="title">开放报告</p>
        <p>手动开放报告</p>
      </div>
      <div class="box">
        <img class="title-img" src="@/assets/project/success4.png" alt="">
        <p class="title">定向测评</p>
        <p>{{ exam.needProfile === 1 ? "是" : "否" }}</p>
      </div>
      <div class="box"  v-if="exam.needProfile === 0">
        <img class="title-img" src="@/assets/project/success5.png" alt="">
        <p class="title">测评人数</p>
        <p>{{ exam.totalNum }}人</p>
      </div>
      <div class="box">
        <img class="title-img" src="@/assets/project/success6.png" alt="">
        <p class="title">测评量表</p>
        <p>{{projectsNames.join('、')}}</p>
      </div>
      <div class="box">
        <img class="title-img" src="@/assets/project/success7.png" alt="">
        <p class="title">发布方式1</p>
        <div style="display: flex;flex-direction: column;">
          <p>小程序测评</p>
          <span>测评人员微信扫码进入测评</span>
          <img :src="exam.imgExamQrcode" alt="" style="width: 34.1333vw;height: 34.1333vw;margin-top:2.6667vw ;">
        </div>
      </div>
      <div class="box">
        <img class="title-img" src="@/assets/project/success7.png" alt="">
        <p class="title">发布方式2</p>
        <div style="display: flex;flex-direction: column;">
          <p>电脑测评</p>
          <span>将下方pc端测评链接发布，在电脑上打开进行测试</span>
          <span style="word-break: break-all;text-decoration: underline;color:#59B2F8 ">{{exam.pcUrl}}</span>
        </div>
      </div>
    </div>
    <div class="bottom" >
      <button @click="$router.push('index')">返回测评筛查列表</button>
    </div>
  </div>
</template>

<script>
import {examDetail,getProjectList} from '@/api/exam.js'
export default {
  data(){
    return{
      exam:{},
      projectsNames:[],
    }
  },
  created(){
    this.getExamDetail()
  },
  methods:{
    async getExamDetail(){
      const id=this.$route.query.id
      const res = await examDetail({id})
      this.exam=res.data.exam
      this.getProjects()
    },
    async getProjects(){
      const res = await getProjectList()
      const projects = res.data.projects
      const projectIds=this.exam.projectId_man.split(',')
      // console.log(projectIds);
      projectIds.forEach(item=>{
        this.projectsNames.push(projects.find(project => project.id === item * 1).name)
      })
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  padding: 6.1333vw 0 18.8667vw 0;
  min-height: 100%;
  background-color: rgb(248, 248, 248);
  .head-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 52.2667vw;
    background: linear-gradient(180deg, #C4F3DF 0%, #FFFFFF 100%);
    z-index: 0;
  }
  .steps{
    position: relative;
    padding: 0 8vw;
    height: 22.9333vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: #fff;
    // box-shadow: 0vw .5333vw 1.6vw 0vw rgba(176, 176, 176, 40);
    z-index: 1;
    .step{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #8DC2BB;
      &::before{
          content: '';
          top: 4vw;
          left: 100%;
          position: absolute;
          width: 20vw;
          height: 1.3333vw;
          background: #BDF1E4;
          border-radius: .8vw .8vw .8vw .8vw;
        }
      &:last-child{
        &::before{
          display: none;
        }
      }
      p{
        margin-top: 2.1333vw;
      }
      img{
        width: 8vw;
        height: 8vw;
        border-radius: 50%;
      }
      .circle{
        position: relative;
        // margin-bottom: 1.8667vw;
        width: 8vw;
        height: 8vw;
        line-height: 8vw;
        font-size: 4.2667vw;
        font-weight: 550;
        text-align: center;
        border-radius: 50%;
        color: #fff;
        background-color: #8dc2bb;
        // border: .2667vw solid rgba(187, 187, 187, 1);
      
      }
    }
    .step.achieve{
      color: #5EDBC9;
    }
  }
  .content{
    position: relative;
    // width: 100%;
    margin: 2.6667vw 3.4667vw 0 3.4667vw;
    padding: 8vw 8vw 2.6667vw 8vw;
    font-size: 3.7333vw;
    border-radius: 2.6667vw;
    background-color: #fff;
    .box{
      padding: 4.8vw 0;
      display: flex;
      justify-content: flex-start;
      border-bottom: .2667vw solid #F1F1F1;
      &:last-child{
        border: none;
      }
      // flex-wrap: wrap;
      .title-img{
        width: 5.3333vw;
        height: 5.3333vw;
      }
      .title{
          flex-shrink: 0;
          margin: 0 4vw 0 2.6667vw;
          color: #000;
          // font-weight: 550;
          font-size: 3.7333vw;
      }
      p{
        color: #999;
        font-size: 4vw;
        line-height: 5.3333vw;
      }
      span{
        margin-top: 1.3333vw;
        color: rgba(113, 113, 113, 1);
        line-height: 4.2667vw;
        font-size: 3.2vw;
      }
    }

  }
  .bottom{
    padding: 0 3.4667vw;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 16vw;
    line-height: 16vw;
    button{
      width: 100%;
      height: 14.4vw;
      line-height: 14.4vw;
      text-align: center;
      font-size: 4.8vw;
      color: rgba(255, 255, 255, 1);
      background-color: #5EDBC9;
      border-radius: 13.3333vw;
    }
  }
}
</style>